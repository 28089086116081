import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/global/contact-form/ContactForm"

const Branding = () => (
  <Layout>
    <Seo title="Branding NSC" />
    <h1 className="text-red">Branding</h1>
    <div className="card card--center-text">
    <p className="center-text">
      I've teamed up with <a href="https://allyrollinson.co.uk/branding/">Studio Calyptus</a>  to offer top level branding as this is not my specialty, but it is theirs.
    </p>
    <p className="center-text">The single most important thing you can do for your brand is having a relevant, distinct and unique logo design. However a great logo design is nothing without a brand identity and devices to accompany it. <a href="https://allyrollinson.co.uk/branding/">Studio Calyptus</a> are here to work with you create the entire look and feel of your brand from scratch or give your exisiting look a refresh custom-made to your requirements.</p>
   </div>
    <ContactForm />
  </Layout>
)

export default Branding
